import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import router from "./routes/index";
import i18n from "@/i18n";
import store from "./store";
import VueExcelXlsx from "vue-excel-xlsx";
import VueMeta from "vue-meta";
import VueQrcodeReader from "vue-qrcode-reader";
import VueKonva from 'vue-konva';
import VueClipboard  from "vue-clipboard2";
import browserDetect from "vue-browser-detect-plugin";
import VueSocialSharing from 'vue-social-sharing'
import titleMixin from "@/mixins/titleMixin";
import VueWordCloud from 'vuewordcloud';
import VueNumber from 'vue-number-animation'
import Ads from 'vue-google-adsense'
import x5GMaps from 'x5-gmaps';
import VCalendar from 'v-calendar';
import birthDatepicker from 'vue-birth-datepicker';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCountdown from '@chenfengyuan/vue-countdown';
import Carousel3d from 'vue-carousel-3d';
import device from "vue-device-detector"
import IdleVue from 'idle-vue';
import VueMarqueeSlider from 'vue-marquee-slider';

window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true
Vue.mixin(titleMixin)
Vue.use(VueExcelXlsx);
Vue.use(Carousel3d);
Vue.use(device)

Vue.component(VueCountdown.name, VueCountdown);
Vue.use(birthDatepicker);
Vue.use(VueMeta);
Vue.use(VueQrcodeReader);
Vue.use(VueKonva, { prefix: 'Konva'});
Vue.use(VueClipboard);
Vue.use(browserDetect);
Vue.use(VueSocialSharing);
Vue.component(VueWordCloud.name, VueWordCloud);
Vue.use(VueNumber);
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
Vue.use(x5GMaps, { key: 'AIzaSyCXm1SR1hOSNMDrCOeMQr7XQSDSS-YKyBg', libraries: ['places'] });
Vue.use(VueMarqueeSlider)



Vue.use(VCalendar, {
    componentPrefix: 'vc',
    locales:{
        'es-PE': {
            firstDayOfWeek:2,
            masks: {
                L: 'DD/MM/YYYY',
                // ...optional `title`, `weekdays`, `navMonths`, etc
            }
        }
    }
});

Vue.use(VueReCaptcha, {
    siteKey: '6LeMXdUUAAAAADqfrL572D4mJ9_j0p0tc-HADIsj',
    loaderOptions: {
        useRecaptchaNet: true
    }
});


const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 10000,
    // startAtIdle: false,
    events: [
        'mousemove',
        'keydown',
        'mousedown',
        'touchstart',
        'scroll',
        'input',
    ],
});


new Vue({
    i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
